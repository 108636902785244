import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './ActivateChannelCodeForm.css'

import FormStatus from '../FormStatus'
import Input from '../../formControls/Input'
import Button from '../../buttons/Button'

const ActivateChannelCodeForm = ({ data, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         code: ''
      },
      validationSchema: yup.object().shape({
         code: yup.string()
            .required('Введите код подтверждения'),
      }),
      onSubmit: async ({ code }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit({
               code,
               sourceId: data
            })
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <form 
         className='add-channel-code-form'
         onSubmit={formik.handleSubmit}   
      >
         {formik.status && <FormStatus text={formik.status} />}
         <Input
            label='Код подтверждения'
            id='code'
            name='code'
            value={formik.values.code}
            error={formik.touched.code && formik.errors.code}
            placeholder='55 00'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
         />
         <Button
            className='add-channel-code-form__submit-btn'
            type='submit'
            text='Активировать канал'
            disabled={disableSubmitBtn}
         />
      </form>
   )
}

export default ActivateChannelCodeForm