import $axios from "../http"

class ChannelsService {
   static fetchChannels = async () => {
      const { data } = await $axios.get('channel/get')
      return data
   }

   static createChannel = async (body) => {
      const { data } = await $axios.post('channel/create', body)
      return data
   }

   static checkIsQrCodeScanned = async (id) => {
      const { data } = await $axios.get(`channel/qrCheck/${id}`)
      return data
   }

   static setChannel = async (body) => {
      const { data } = await $axios.post('channel/set', body)
      return data
   }

   static setChannelWithPassword = async (body) => {
      const { data } = await $axios.post('channel/two-factor-set', body)
      return data
   }

   static activateChannel = async (body) => {
      const { data } = await $axios.post(`/channel/activate`, body)
      return data
   }

   static reactivateChannel = async (body) => {
      const { data } = await $axios.post(`/channel/create`, {
         ...body,
         channelReactivate: true
      })
      return data
   }

   static deactivateChannel = async (id) => {
      const { data } = await $axios.post(`channel/deactivate/${id}`)
      return data
   }

   static deleteChannel = async (id) => {
      const { data } = await $axios.delete(`channel/delete/${id}`)
      return data
   }
}

export default ChannelsService