import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import './CrmPage.css'

import routeNames from '../../router/routeNames'
import { crms_selectCrms, crms_selectCrmsLoading } from '../../store/slices/crms/selectors'
import { deleteCrmChannel, fetchCrms, updateCrmChannel } from '../../store/slices/crms/thunk-creators'

import Page from '../../components/layouts/Page'
import CrmChannelsList from './CrmChannelsList'
import EmptyListMessage from '../../components/layouts/EmptyListMessage'
import Button from '../../components/buttons/Button'
import DeleteChannelModal from '../../components/modals/DeleteChannelModal'
import Loader from '../../components/layouts/Loader'

const CrmPage = () => {
   const channelsLoading = useSelector(crms_selectCrmsLoading)
   const channels = useSelector(crms_selectCrms)

   const [channelIdToDelete, setChannelIdToDelete] = useState(null)
   const [showDeleteChannelModal, setShowDeleteChannelModal] = useState (false)

   const navigate = useNavigate()
   const dispatch = useDispatch()

   const handleAddChannelBtnClick = () => navigate(routeNames.CRM_ADD)

   const handleFetchCrms = async () => {
      dispatch(fetchCrms())
   }

   const handleChannelStatusChange = async (data) => {
      await dispatch(updateCrmChannel(data))
   }

   const handleEditBtnClick = (id) => {
      navigate(routeNames.CRM + `/${id}`)
   }

   const handleToggleDeleteChannelModal = () => setShowDeleteChannelModal(show => !show)

   const handleDeleteBtnClick = (id) => {
      setChannelIdToDelete(id)
      handleToggleDeleteChannelModal()
   }

   const handleChannelDelete = async (id) => {
      await dispatch(deleteCrmChannel(id))
      handleToggleDeleteChannelModal()
   }

   useEffect(() => {
      handleFetchCrms()
   }, [])

   if (channelsLoading) return <Loader />

   return (
      <Page title='CRM'>
         {channels.length > 0
            ? (
               <div className='crm-page__body'>
                  <CrmChannelsList
                     channels={channels}
                     handleStatusChange={handleChannelStatusChange}
                     handleEditBtnClick={handleEditBtnClick}
                     handleDeleteBtnClick={handleDeleteBtnClick}
                  />
                  <Button
                     className='crm-page__add-channel-btn'
                     text='Добавить канал'
                     onClick={handleAddChannelBtnClick}
                  >
                     <img src='/assets/icons/plus-icon.svg' alt='Add channel' />
                  </Button>
                  <DeleteChannelModal
                     channelIdToDelete={channelIdToDelete}
                     isVisible={showDeleteChannelModal}
                     onClose={handleToggleDeleteChannelModal}
                     onCancel={handleToggleDeleteChannelModal}
                     onConfirm={handleChannelDelete}
                  />
               </div>
            ) : (
               <EmptyListMessage
                  imgSrc='/assets/images/crm-empty.png'
                  text='Вы еще не добавили ни одну CRM-систему.'
                  btnText='Добавить CRM'
                  onClick={handleAddChannelBtnClick}
               />
            )
         }
      </Page>
   )
}

export default CrmPage