import React from 'react'
import BasicModal from '../BasicModal'
import ActivateChannelCodeForm from '../../forms/ActivateChannelCodeForm'

const ActivateChannelCodeModal = ({ isVisible, data, onClose, onSubmit }) => {
   return (
      <BasicModal
         title='Активировать канал'
         isVisible={isVisible}
         onClose={onClose}
      >
         <ActivateChannelCodeForm
            data={data}
            onSubmit={onSubmit}
         />
      </BasicModal>
   )
}

export default ActivateChannelCodeModal