import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './ResetPasswordForm.css'

import Input from '../../formControls/Input'
import Button from '../../buttons/Button'
import FormStatus from '../FormStatus'

const ResetPasswordForm = ({ onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         password: '',
         repeatPassword: ''
      },
      validationSchema: yup.object().shape({
         password: yup.string()
            .min(6, 'Длина пароля должна быть длинее 6 символов')
            .required('Введите пароль'),
         repeatPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
            .required('Поле "Повторить пароль" обязательно для заполнения')
      }),
      onSubmit: async ({ password }, { setStatus }) => {
         try {
            setDisableSubmitBtn(false)
            await onSubmit({ password })
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <form 
         className='reset-password-form'
         onSubmit={formik.handleSubmit}   
      >
         <div className='reset-password-form__header'>
            <h4 className='reset-password-form__title'>
               Восстановление пароля
            </h4>
         </div>
         <div className='reset-password-form__body'>
            {formik.status && <FormStatus text={formik.status} />}
            <Input
               type='password'
               label='Пароль'
               id='password'
               name='password'
               value={formik.values.password}
               error={formik.touched.password && formik.errors.password}
               placeholder='******'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
            <Input
               type='password'
               label='Повторите пароль'
               id='repeatPassword'
               name='repeatPassword'
               value={formik.values.repeatPassword}
               error={formik.touched.repeatPassword && formik.errors.repeatPassword}
               placeholder='******'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
         </div>
         <div className='reset-password-form__footer'>
            <Button
               type='submit'
               className='auth-form__submit-btn'
               text='Воcстановить'
               disabled={disableSubmitBtn}
            />
         </div>
      </form>
   )
}

export default ResetPasswordForm