import React from 'react'
import './EmptyListMessage.css'
import Button from '../../buttons/Button'

const EmptyListMessage = ({ imgSrc = '', text = '', btnText = '', onClick  }) => {
   return (
      <div className='empty-list-message'>
         <img className='empty-list-message__img' src={imgSrc} alt='' />
         <p className='empty-list-message__text'>{text}</p>
         <Button
            className='empty-list-message__btn'
            text={btnText}
            onClick={onClick}
         >
            <img src='/assets/icons/plus-icon.svg' alt='Add' />
         </Button>
      </div>
   )
}

export default EmptyListMessage