import React from 'react'
import './FormStatus.css'

const FormStatus = ({ className = '', text }) => {
   return (
      <h4 className={`form-status ${className}`}>
         {text}
      </h4>
   )
}

export default FormStatus