import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './CrmInfoPage.css'

import { crms_selectCrmData } from '../../store/slices/crms/selectors'
import { deleteCrmChannel, fetchCrmData, updateCrmChannel } from '../../store/slices/crms/thunk-creators'
import { channelsMap } from '../CrmPage/utils/constants'
import routeNames from '../../router/routeNames'

import Page from '../../components/layouts/Page'
import CrmInfoPageDataItem from './CrmInfoPageDataItem'
import Toggle from '../../components/formControls/Toggle'
import EditCrmManagerModal from '../../components/modals/EditCrmManagerModal'
import DeleteChannelModal from '../../components/modals/DeleteChannelModal'
import Loader from '../../components/layouts/Loader'

const CrmInfoPage = () => {
   const { crmId } = useParams()

   const { id, channel, status, username, domain } = useSelector(crms_selectCrmData)
   const isChannelActive = status?.id === 1

   const [isLoading, setIsLoading] = useState(true)
   const [disableToggle, setDisableToggle] = useState(false)
   const [showEditCrmManagerModal, setShowEditCrmManagerModal] = useState(false)
   const [showDeleteChannelModal, setShowDeleteChannelModal] = useState(false)

   const pageTitle = channelsMap[channel?.type]?.channelName || 'Исходящий канал'

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleStatusChange = async () => {
      setDisableToggle(true)
      await dispatch(updateCrmChannel({
         id,
         body: {
            status: { id: isChannelActive ? 0 : 1 }
         }
      }))
      setDisableToggle(false)
   }

   const handleToggleEditCrmManagerModal = () => setShowEditCrmManagerModal(show => !show)
   const handleToggleDeleteChannelModal = () => setShowDeleteChannelModal(show => !show)

   const handleFetchCrmData = async () => {
      await dispatch(fetchCrmData(crmId))
   }

   const handleEditCrmManagerModalSubmit = async (data) => {
      try {
         await dispatch(updateCrmChannel({
            id,
            body: data
         }))
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const handleDeleteChannelModalSubmit = async (id) => {
      await dispatch(deleteCrmChannel(id))
      navigate(routeNames.CRM)
   }

   useEffect(() => {
      if (crmId) handleFetchCrmData(crmId)
            .then(() => setIsLoading(false))
   }, [])

   if (isLoading) return <Loader />

   return (
      <Page
         title={pageTitle}
         returnBtn
      >
         <div className='update-crm-page-body'>
            <CrmInfoPageDataItem
               dataItemName={'Домен портала'}
            >
               <div className='update-crm-page-data-item__value'>
                  {domain || '-'}
               </div>
            </CrmInfoPageDataItem>
            {channel?.type === 'amo' && (
               <CrmInfoPageDataItem
                  dataItemName={'Ответственный за заявки'}
               >
                  <div className='update-crm-page-data-item__value'>
                     {username || '-'}
                  </div>
                  <div className='update-crm-page-data-item__actions'>
                     <button
                        className='update-crm-page-data-item__edit-btn'
                        type='button'
                        onClick={handleToggleEditCrmManagerModal}
                     >
                        <img src='/assets/icons/edit-icon.svg' alt='Edit' />
                     </button>
                  </div>
               </CrmInfoPageDataItem>
            )}
            <CrmInfoPageDataItem
               dataItemName={'Статус'}
            >
               <div className='update-crm-page-data-item__value'>
                  <div className={`update-crm-page-data-item__status ${isChannelActive ? 'update-crm-page-data-item__status_active' : ''}`}>
                     {isChannelActive ? 'Активный' : 'Неактивный'}
                  </div>
               </div>
               <div className='update-crm-page-data-item__actions'>
                  <Toggle
                     checked={isChannelActive}
                     onChange={handleStatusChange}
                     disabled={disableToggle}
                  />
               </div>
            </CrmInfoPageDataItem>
            <button 
               className='update-crm-page-delete-btn'
               type='button'
               onClick={handleToggleDeleteChannelModal}   
            >
               Удалить канал
            </button>
            <EditCrmManagerModal
               isVisible={showEditCrmManagerModal}
               manager={username}
               onClose={handleToggleEditCrmManagerModal}
               onCancel={handleToggleEditCrmManagerModal}
               onSubmit={handleEditCrmManagerModalSubmit}
            />
            <DeleteChannelModal
               channelIdToDelete={id}
               isVisible={showDeleteChannelModal}
               onClose={handleToggleDeleteChannelModal}
               onCancel={handleToggleDeleteChannelModal}
               onConfirm={handleDeleteChannelModalSubmit}
            />
         </div>
      </Page>
   )
}

export default CrmInfoPage