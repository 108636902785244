import React from 'react'
import BasicModal from '../BasicModal'
import AddBitrixChannelForm from '../../forms/AddBitrixChannelForm'

const AddBitrixChannelModal = ({ isVisible, data, onClose, onSubmit }) => {
   return (
      <BasicModal
         title='Добавить канал'
         isVisible={isVisible}
         onClose={onClose}
      >
         {isVisible && (
            <AddBitrixChannelForm
               data={data}
               onSubmit={onSubmit}
            />
         )}
      </BasicModal>
   )
}

export default AddBitrixChannelModal