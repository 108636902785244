import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './AddChannelPage.css'

import { auth_selectUser } from '../../store/slices/auth/selectors'
import routeNames from '../../router/routeNames'
import useCheckIsChannelCreationAllowed from '../../hooks/useCheckIsChannelCreationAllowed'
import { channelTypes } from '../../utils/constants'
import { getIsPlanEnded } from '../PlanPage/utils'

import Page from '../../components/layouts/Page'

const AddChannelPage = () => {
   const { planEndAt } = useSelector(auth_selectUser)

   const isWALimitMaxedOut = useCheckIsChannelCreationAllowed(channelTypes.WHATSAPP)
   const isTGLimitMaxedOut = useCheckIsChannelCreationAllowed(channelTypes.TELEGRAM)
   const isPlanEnded = getIsPlanEnded(planEndAt)

   const navigate = useNavigate()
   
   const handleAddChannelLinkClick = (channelType) => {
      let isChannelLimitMaxedOut
      switch (channelType) {
         case channelTypes.WHATSAPP:
            isChannelLimitMaxedOut = isWALimitMaxedOut
            break
         default:
            isChannelLimitMaxedOut = isTGLimitMaxedOut
      }
      if (isPlanEnded) {
         alert('Тариф закончился! Продлите тариф, чтобы создать канал.')
      } else if (isChannelLimitMaxedOut) {
         alert('У вас уже создано максимальное количество каналов данного типа для текущего тарифа! Расширьте тариф, чтобы создавать больше.')
      } else {
         navigate(routeNames.MESSENGERS_ADD + `/${channelType}`)
      }
   }

   return (
      <Page
         title='Добавить канал'
         returnBtn
      >
         <div className='add-channel-page-body'>
            <button
               className='add-channel-page-link add-channel-page-link_whatsapp'
               onClick={() => handleAddChannelLinkClick(channelTypes.WHATSAPP)}
            >
               <img src='/assets/icons/add-channel-whatsapp-icon.svg' alt='' />
               WhatsApp
            </button>
            <button
               className='add-channel-page-link add-channel-page-link_telegram'
               onClick={() => handleAddChannelLinkClick(channelTypes.TELEGRAM)}
            >
               <img src='/assets/icons/add-channel-telegram-icon.svg' alt='' />
               Telegram
            </button>
         </div>
      </Page>
   )
}

export default AddChannelPage