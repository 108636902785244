import React from 'react'
import './ChannelsList.css'
import ChannelsListItem from './ChannelsListItem'

const ChannelsList = ({ channels, handleStatusChange, handleDeleteBtnClick }) => {
   return (
      <div className='channels-list'>
         <div className='channels-list__header'>
            <div className='channels-list__column channels-list__column_channel'>
               Канал
            </div>
            <div className='channels-list__column channels-list__column_phone'>
               Телефон
            </div>
            <div className='channels-list__column channels-list__column_status'>
               Статус
            </div>
            <div className='channels-list__column channels-list__column_actions'>
            </div>
         </div>
         <ul className='channels-list__list'>
            {channels.map(channel => (
               <ChannelsListItem
                  key={channel.id}
                  channelData={channel}
                  onStatusChange={handleStatusChange}
                  onDeleteBtnClick={handleDeleteBtnClick}
               />
            ))}
         </ul>
      </div>
   )
}

export default ChannelsList