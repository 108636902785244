import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './PlanPage.css'

import { plan_selectPlan, plan_selectPlanIsLoading } from '../../store/slices/plan/selectors'
import { fetchPlan } from '../../store/slices/plan/thunk-creators'

import Loader from '../../components/layouts/Loader'
import Plan from './Plan'

const PlanPage = () => {
   const planIsLoading = useSelector(plan_selectPlanIsLoading)
   const plan = useSelector(plan_selectPlan)

   const dispatch = useDispatch()

   const handleFetchPlan = async () => {
      await dispatch(fetchPlan())
   }

   useEffect(() => {
      handleFetchPlan()
   }, [])

   if (!plan || planIsLoading) return <Loader />

   return <Plan plan={plan} />
}

export default PlanPage