import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './ForgotPasswordForm.css'

import Input from '../../formControls/Input'
import Button from '../../buttons/Button'
import FormStatus from '../FormStatus'

const ForgotPasswordForm = ({ onReturnBtnClick, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         email: ''
      },
      validationSchema: yup.object().shape({
         email: yup.string()
            .email('Некорректный формат электронной почты')
            .required('Введите почту'),
      }),
      onSubmit: async (values, { setStatus }) => {
         try {
            setDisableSubmitBtn(false)
            await onSubmit(values)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <form 
         className='forgot-password-form'
         onSubmit={formik.handleSubmit}   
      >
         <div className='forgot-password-form__header'>
            <div className='forgot-password-form__title'>
               <button
                  className='forgot-password-form__return-btn'
                  type='button'
                  onClick={onReturnBtnClick}
               >
                  <img src='/assets/icons/return-icon.svg' alt='Return' />
               </button>
               <h4 className='forgot-password-form__title-text'>
                  Восстановление пароля
               </h4>
            </div>
            <p className='forgot-password-form__title-description'>
               Введите почту, которую вы указали при регистрации
            </p>
         </div>
         <div className='forgot-password-form__body'>
            {formik.status && <FormStatus text={formik.status} />}
            <Input
               type='email'
               label='Почта'
               id='email'
               name='email'
               value={formik.values.email}
               error={formik.touched.email && formik.errors.email}
               placeholder='Введите почту'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
         </div>
         <div className='forgot-password-form__footer'>
            <Button
               type='submit'
               className='auth-form__submit-btn'
               text='Далее'
               disabled={disableSubmitBtn}
            />
         </div>
      </form>
   )
}

export default ForgotPasswordForm