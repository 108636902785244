import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import './App.css'

import { checkAuth } from './store/slices/auth/thunk-creators'
import AppRouter from './router/AppRouter'
import Loader from './components/layouts/Loader'

const App = () => {
   const [appInitialized, setAppInitialized] = useState(false)
   const dispatch = useDispatch()

   const handleCheckAuth = async () => {
      await dispatch(checkAuth())
      setAppInitialized(true)
   }

   useEffect(() => {
      handleCheckAuth()
   }, [])

   return (
      <div className='wrapper'>
         {!appInitialized
            ? <Loader />
            : <AppRouter />
         }
      </div>
   )
}

export default App