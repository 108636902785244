import React from 'react'
import './CrmInfoPage.css'

const CrmInfoPageDataItem = ({ dataItemName, children }) => {
   return (
      <div className='update-crm-page-data-item'>
         <div className='update-crm-page-data-item__name'>
            {dataItemName}
         </div>
         {children}
      </div>
  )
}

export default CrmInfoPageDataItem