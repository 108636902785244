import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { auth_selectIsAuth } from '../store/slices/auth/selectors'
import routeNames from './routeNames'

import MainLayout from '../components/layouts/MainLayout'
import MessengersPage from '../pages/MessengersPage'
import ProfilePage from '../pages/ProfilePage'
import CrmPage from '../pages/CrmPage'
import PlanPage from '../pages/PlanPage'
import AuthPage from '../pages/AuthPage'
import AddChannelPage from '../pages/AddChannelPage'
import AddChannelPageWrapper from '../pages/AddChannelPageWrapper'
import AuthLayout from '../components/layouts/AuthLayout'
import ForgotPasswordPage from '../pages/ForgotPasswordPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import AddCrmPage from '../pages/AddCrmPage'
import CrmInfoPage from '../pages/CrmInfoPage'
import PlanPaymentPage from '../pages/PlanPaymentPage'
import RegistrationSuccessPage from '../pages/RegistrationSuccessPage'
import ResetPasswordEmailSentPage from '../pages/ResetPasswordEmailSentPage'
import ResetPasswordSuccessPage from '../pages/ResetPasswordSuccessPage'

const AppRouter = () => {
   const isAuth = useSelector(auth_selectIsAuth)

   return (
      isAuth
         ? (
            <Routes>
               <Route path={routeNames.HOME} element={<MainLayout />}>
                  <Route index element={<Navigate to={routeNames.PROFILE} />} />
                  <Route path={routeNames.PROFILE} element={<ProfilePage />} />
                  <Route path={routeNames.MESSENGERS} element={<MessengersPage />} />
                  <Route path={routeNames.MESSENGERS_ADD} element={<AddChannelPage />} />
                  <Route path={routeNames.MESSENGERS_ADD_CHANNEL} element={<AddChannelPageWrapper />} />
                  <Route path={routeNames.CRM} element={<CrmPage />} />
                  <Route path={routeNames.CRM + '/:crmId'} element={<CrmInfoPage />} />
                  <Route path={routeNames.CRM_ADD} element={<AddCrmPage />} />
                  <Route path={routeNames.PLAN} element={<PlanPage />} />
                  <Route path={routeNames.PLAN_PAYMENT} element={<PlanPaymentPage />} />
                  <Route path={routeNames.BAD_URL} element={<Navigate to={routeNames.PROFILE} />} />
               </Route>
            </Routes>
         ) : (
            <Routes>
               <Route path={routeNames.HOME} element={<AuthLayout />}>
                  <Route index element={<Navigate to={routeNames.LOGIN} />} />
                  <Route path={routeNames.LOGIN} element={<AuthPage />} />
                  <Route path={routeNames.REGISTRATION} element={<AuthPage />} />
                  <Route path={routeNames.REGISTRATION_SUCCESS} element={<RegistrationSuccessPage />} />
                  <Route path={routeNames.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                  <Route path={routeNames.RESET_PASSWORD_EMAIL_SENT} element={<ResetPasswordEmailSentPage />} />
                  <Route path={routeNames.RESET_PASSWORD} element={<ResetPasswordPage />} />
                  <Route path={routeNames.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccessPage />} />
                  <Route path={routeNames.BAD_URL} element={<Navigate to={routeNames.LOGIN} />} />
               </Route>
            </Routes>
         )
   )
}

export default AppRouter