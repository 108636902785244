import React from 'react'
import './Button.css'

const Button = ({ 
   className = '', 
   type = 'button', 
   text = '', 
   disabled, 
   children, 
   onClick = () => {}
}) => {
   const handleClick = () => {
      onClick()
   }

   return (
      <button
         className={`btn ${className}`}
         type={type}
         disabled={disabled}
         onClick={handleClick}
      >
         {children}
         {text}
      </button>
   )
}

export default Button