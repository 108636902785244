import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import './AddCrmPage.css'

import routeNames from '../../router/routeNames'
import { createAmoChannel, createAmoChannelWithData, createBitrixChannel, createBitrixChannelWithData } from '../../store/slices/crms/thunk-creators'

import Page from '../../components/layouts/Page'
import SelectDealStatusModal from '../../components/modals/SelectDealStatusModal'
import AddBitrixChannelModal from '../../components/modals/AddBitrixChannelModal'

const AddCrmPage = () => {
   let popup

   const [amoChannelData, setAmoChannelData] = useState(null)
   const [showStatusModal, setShowStatusModal] = useState(false)
   const [showBitrixModal, setShowBitrixModal] = useState(false)

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleCloseStatusModal = () => {
      setShowStatusModal(false)
      setAmoChannelData(null)
   }

   const handleToggleBitrixModal = () => setShowBitrixModal(show => !show)

   const handleChannelCreate = async (type, formData) => {
      try {
         const action = type === 'amo' ? createAmoChannel : createBitrixChannel
         const data = await dispatch(action(formData))
         popup = window.open(data, 'Предоставить доступ', 'scrollbars, status, resizable, width=750, height=580')  
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const handleCreateBitrixChannel = async (data) => {
      try {
         await dispatch(createBitrixChannelWithData(data))
         navigate(routeNames.CRM)
      } catch (err) {
         alert(err)
      }
   }

   const handleStatusModalSubmit = async (data) => {
      try {
         await dispatch(createAmoChannelWithData(data))
         handleCloseStatusModal()
         navigate(routeNames.CRM)
      } catch (error) {
         return Promise.reject(error)
      }
   }

   const handleAddBitrixChannelModalSubmit = async (data) => {
      try {
         await handleChannelCreate('bitrix', data)
         handleToggleBitrixModal()
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const handleGettingChannelData = ({ data }) => {
      if (data.targetId && data.statuses) {
         setAmoChannelData(data)
      } else if (data.targetId && !data.statuses) {
         handleCreateBitrixChannel(data)
      }
      
      popup?.close()
   }

   useEffect(() => {
      window.addEventListener('message', handleGettingChannelData)

      return () => window.removeEventListener('message', handleGettingChannelData)
   }, [])

   useEffect(() => {
      if (amoChannelData) setShowStatusModal(true)
   }, [amoChannelData])

   return (
      <Page
         title='Добавить CRM'
         returnBtn
      >
         <div className='add-crm-page-body'>
            <button
               type='button'
               className='add-crm-page-link add-crm-page-link_whatsapp'
               onClick={() => handleChannelCreate('amo')}
            >
               <img src='/assets/icons/add-crm-amo-icon.svg' alt='' />
            </button>
            <button
               type='button'
               className='add-crm-page-link add-crm-page-link_telegram'
               onClick={handleToggleBitrixModal}
            >
               <img src='/assets/icons/add-crm-bitrix-icon.svg' alt='' />
            </button>
         </div>
         {amoChannelData && (
            <SelectDealStatusModal
               isVisible={showStatusModal}
               data={amoChannelData}
               onClose={handleCloseStatusModal}
               onSubmit={handleStatusModalSubmit}
            />
         )}
         <AddBitrixChannelModal
            isVisible={showBitrixModal}
            onClose={handleToggleBitrixModal}
            onSubmit={handleAddBitrixChannelModalSubmit}
         />
      </Page>
   )
}

export default AddCrmPage