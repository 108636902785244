import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import routeNames from '../../router/routeNames'
import { login, registerUser } from '../../store/slices/auth/thunk-creators'

import AuthForm from '../../components/forms/AuthForm'

const AuthPage = () => {
   const { pathname } = useLocation()
   const isLoginPage = pathname === routeNames.LOGIN
   
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleSubmit = async (data) => {
      try {
         const action = isLoginPage ? login : registerUser 
         await dispatch(action(data)) 
         if (!isLoginPage) navigate(routeNames.REGISTRATION_SUCCESS)
      } catch (err) {
         return Promise.reject(err)
      }
   }

   return (
      <AuthForm
         isLoginPage={isLoginPage}
         onSubmit={handleSubmit}
      />
   )
}

export default AuthPage