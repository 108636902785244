const routeNames = {
   HOME: '/',
   LOGIN: '/login',
   REGISTRATION: '/registration',
   REGISTRATION_SUCCESS: '/registration/success',
   FORGOT_PASSWORD: '/forgot-password',
   RESET_PASSWORD: '/user/reset-password',
   RESET_PASSWORD_SUCCESS: '/reset-password/success',
   RESET_PASSWORD_EMAIL_SENT: '/reset-password/email-sent',
   PROFILE: '/profile',
   MESSENGERS: '/messengers',
   MESSENGERS_ADD: '/messengers/add',
   MESSENGERS_ADD_WHATSAPP: '/messengers/add/whatsapp',
   MESSENGERS_ADD_CHANNEL: '/messengers/add/:channel',
   CRM: '/crm',
   CRM_ADD: '/crm/add',
   CRM_INFO: '/crm/:id',
   PLAN: '/plan',
   PLAN_PAYMENT: '/plan/payment',
   BAD_URL: '/*'
}

export default routeNames