import { checkAuthSuccess, logoutSuccess, updateUserSuccess } from "."
import AuthService from "../../../services/authService"

export const checkAuth = () => async (dispatch) => {
   try {
      const data = await AuthService.fetchUser()
      dispatch(checkAuthSuccess(data))
   } catch (err) {
      console.log(err)
   }
}

export const registerUser = (formData) => async (dispatch) => {
   try {
      await AuthService.register(formData)
   } catch (err) {
      if (err?.response?.data?.message === 'Пользователь с такими учетными данными уже зарегистрирован в системе.') {
         return Promise.reject('Пользователь с таким email или номером телефона уже зарегистрирован.')
      } else if (err?.response?.data?.message) {
         return Promise.reject(err?.response?.data?.message)
      } else {
         return Promise.reject('Не удалось завершить регистрацию')
      }
   }
}

export const login = (formData) => async (dispatch) => {
   try {
      await AuthService.login(formData)
      dispatch(checkAuth())
   } catch (err) {
      if (
         err?.response?.data?.error === 'Bad credentials.' ||
         err?.response?.data?.error === 'The presented password is invalid.'
      ) {
         return Promise.reject('Неправильный email или пароль')
      }
   }
}

export const logout = () => async (dispatch) => {
   try {
      await AuthService.logout()
      dispatch(logoutSuccess())
   } catch (err) {
      console.log(err)
   }
}

export const requestResetPassword = (formData) => async (dispatch) => {
   try {
      const data = await AuthService.requestResetPassword(formData)
      
      if (data.message !== 'Данные для сброса пароля отправлены') {
         return Promise.reject('Не удалось отправить данные для сброса пароля.')
      }
   } catch (err) {
      if (err.response.status === 404) {
         return Promise.reject(err.response.data.message)
      }
   }
}

export const resetPassword = (formData) => async (dispatch) => {
   try {
      await AuthService.resetPassword(formData)

   } catch (err) {
      if (err.response.status === 404) {
         if (err.response.data.message === 'Неправильный токен сброса пароля') {
            return Promise.reject({
               status: 'Срок действия ссылки для восстановления пароля истек',
               redirectToForgotPasswordPage: true
            })
         }
      } else {
         return Promise.reject('Ошибка восстановления пароля')
      }
   }
}

export const updateUser = (data) => async (dispatch) => {
   try {
      await AuthService.updateUser(data)
      dispatch(updateUserSuccess(data))
   } catch (err) {
      if (err?.response?.data?.error) return Promise.reject(err?.response?.data?.error)
      if (err?.response?.data?.message) return Promise.reject(err?.response?.data?.message)
      return Promise.reject('Не удалось обновить данные!')
   }
}