import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   plan: null,
   planIsLoading: false,
   calculationError: false
}

const planSlice = createSlice({
   name: 'plan',
   initialState,
   reducers: {
      fetchPlanStarted: (state) => {
         state.planIsLoading = true
      },
      fetchPlanFinished: (state) => {
         state.planIsLoading = false
      },
      fetchPlanSuccess: (state, action) => {
         state.plan = action.payload
      },
      changePlanSuccess: (state, action) => {
         state.plan = action.payload
      },
      setCalculationError: (state, action) => {
         state.calculationError = action.payload
      }
   }
})

export const {
   fetchPlanStarted,
   fetchPlanFinished,
   fetchPlanSuccess,
   changePlanSuccess,
   setCalculationError
} = planSlice.actions
export default planSlice.reducer