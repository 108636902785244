import { combineReducers, configureStore } from '@reduxjs/toolkit'
import auth from './slices/auth'
import channels from './slices/channels'
import crms from './slices/crms'
import plan from './slices/plan'

const rootReducer = combineReducers({
   auth,
   channels,
   crms,
   plan
})

const store = configureStore({
   reducer: rootReducer
})

export default store