import React, { useState } from 'react'
import './Input.css'

const Input = ({
   label = '',
   type = 'text',
   id,
   name,
   value,
   error,
   placeholder = '',
   autoComplete,
   maxLength,
   onChange = () => {},
   onBlur = () => {}
}) => {
   const [showPassword, setShowPassword] = useState(false)

   const handleToggleInputType = () => setShowPassword(show => !show)

   return (
      <div className={`input ${type === 'password' ? 'input_password' : ''} ${error ? 'error' : ''}`}>
         <label 
            htmlFor={id}
            className='input__label'
         >
            {label}
         </label>
         <div className='input__container'>
            {type === 'password'
               ? (
                  <input
                     className='input__input'
                     type={showPassword ? 'text' : 'password'}
                     id={id}
                     name={name}
                     value={value}
                     onChange={onChange}
                     onBlur={onBlur}
                     placeholder={placeholder} 
                     autoComplete='off'
                  />
               ) : (
                  <input
                     className='input__input'
                     type={type}
                     id={id}
                     name={name}
                     value={value}
                     onChange={onChange}
                     onBlur={onBlur}
                     placeholder={placeholder} 
                     autoComplete={autoComplete || name}
                     maxLength={maxLength}
                  />
               )}
            <button
               className='input__password-visibility-toggle'
               type='button'
               onClick={handleToggleInputType}
            >
               <img 
                  src={showPassword 
                     ? '/assets/icons/password-visible-icon.svg'
                     : '/assets/icons/password-hidden-icon.svg'
                  } 
                  alt='' 
               />
            </button>
         </div>
         {error && (
            <p className='input__error-message'>
               {error}
            </p>
         )}
      </div>
   )
}

export default Input