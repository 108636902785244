import { channelTypes } from "../../utils/constants";
import AddWhatsappPage from "../AddWhatsappPage";
import MessengersPage from "../MessengersPage";

export const getPageFromChannelParam = (param) => {
   switch (param) {
      case channelTypes.TELEGRAM:
         return MessengersPage
      case channelTypes.WHATSAPP:
         return AddWhatsappPage
      default:
         return
   }
}