import React, { useEffect, useState } from 'react'
import './QtySelect.css'

const QtySelect = ({ quantity, disableIncreaseBtn, onChange = () => {} }) => {
   const [qty, setQty] = useState(quantity)

   const disableDecreaseBtn = qty < 2

   const handleDecreaseBtnClick = () => {
      setQty(qty => qty - 1)
      onChange(qty - 1)
   }
   const handleIncreaseBtnClick = () => {
      setQty(qty => qty + 1)
      onChange(qty + 1)
   }

   useEffect(() => {
      setQty(quantity)
   }, [quantity])

   return (
      <div className='qty-select'>
         <label
            className='qty-select__label'
         >
            Количество
         </label>
         <div className='qty-select__select'>
            <button 
               className='qty-select__btn qty-select__decrease-btn'
               type='button'
               disabled={disableDecreaseBtn}
               onClick={handleDecreaseBtnClick}
            >
               <svg width="16" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1H17" stroke="#5570FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </button>
            <span className='qty-select__value'>
               {qty}
            </span>
            <button 
               className='qty-select__btn qty-select__increase-btn'
               type='button'
               onClick={handleIncreaseBtnClick}
               disabled={disableIncreaseBtn}
            >
               <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7H7M7 7H13M7 7V1M7 7V13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </button>
         </div>
      </div>
   )
}

export default QtySelect