import React from 'react'
import './Radio.css'

const Radio = ({
   text = '',
   checked = false,
   disabled,
   onChange = () => {}
}) => {
   const handleChange = () => {
      onChange()
   }

   return (
      <div className={`radio ${checked ? 'active' : ''}`}>
         <label className='radio__label'>
            <input
               className='radio__input'
               type='radio'
               checked={checked}
               disabled={disabled}
               onChange={handleChange}
            />
            <div className='radio__radio'></div>
            {text && (
               <p className='radio__text'>
                  {text}
               </p>
            )}
         </label>
      </div>
   )
}

export default Radio