import React from 'react'
import BasicModal from '../BasicModal'
import EditProfileDataItemForm from '../../forms/EditProfileDataItemForm'

const getEditProfileDataItemModalTitle = (dataItem) => {
   const title = 'Изменить '
   switch (dataItem?.dataType) {
      case 'text':
         return title + 'имя'
      case 'email':
         return title + 'почту'
      case 'tel':
         return title + 'телефон'
      default:
         return title + 'поле'
   }
}

const EditProfileDataItemModal = ({ 
   profileDataItemToEdit, 
   isVisible, 
   onClose,
   onCancel,
   onSubmit
}) => {
   const title = getEditProfileDataItemModalTitle(profileDataItemToEdit)

   return (
      <BasicModal
         title={title}
         isVisible={isVisible}
         onClose={onClose}
      >
         <EditProfileDataItemForm
            profileDataItemToEdit={profileDataItemToEdit}
            onCancel={onCancel}
            onSubmit={onSubmit}
         />
      </BasicModal>
   )
}

export default EditProfileDataItemModal