import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import routeNames from '../../router/routeNames'
import { requestResetPassword } from '../../store/slices/auth/thunk-creators'

import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm'

const ForgotPasswordPage = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleReturnBtnClick = () => navigate(-1)

   const handleSubmit = async (data) => {
      try {
         await dispatch(requestResetPassword(data))
         navigate(routeNames.RESET_PASSWORD_EMAIL_SENT)
      } catch (err) {
         return Promise.reject(err)
      }
   }

   return (
      <ForgotPasswordForm
         onReturnBtnClick={handleReturnBtnClick}
         onSubmit={handleSubmit}
      />
   )
}

export default ForgotPasswordPage