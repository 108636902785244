import { 
   deleteCrmChannelSuccess, 
   fetchCrmDataFinished, 
   fetchCrmDataStarted, 
   fetchCrmDataSuccess, 
   fetchCrmsFinished, 
   fetchCrmsStarted, 
   fetchCrmsSuccess, 
   updateCrmChannelSuccess

} from "."
import CrmsService from "../../../services/crmsService"

export const fetchCrms = () => async (dispatch) => {
   try {
      dispatch(fetchCrmsStarted())
      const data = await CrmsService.fetchChannels()
      dispatch(fetchCrmsSuccess(data))
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchCrmsFinished())
   }
}

export const fetchCrmData = (id) => async (dispatch) => {
   try {
      const data = await CrmsService.fetchChannels(id)
      dispatch(fetchCrmDataSuccess(data))
   } catch (err) {
      console.log(err)
   }
}

export const createAmoChannel = () => async (dispatch) => {
   try {
      const data = await CrmsService.createAmoChannel()
      return data
   } catch (err) {
      return Promise.reject()
   }
}

export const createAmoChannelWithData = (body) => async (dispatch) => {
   try {
      const data = await CrmsService.setAmoChannel(body)

   } catch (err) {
      return Promise.reject('Не удалось подключить канал')
   }
}

export const createBitrixChannel = (body) => async (dispatch) => {
   try {
      const data = await CrmsService.createBitrixChannel(body)
      return data
   } catch (err) {
      console.log(err)
   }
}

export const createBitrixChannelWithData = (body) => async (dispatch) => {
   try {
      const data = await CrmsService.setBitrixChannel(body)

      if (data.message !== 'Канал установлен') return Promise.reject('Не удалось подключить канал')
   } catch (err) {
      if (err?.response?.data?.message) return Promise.reject(err.response.data.message)
      return Promise.reject('Не удалось подключить канал')
   }
}

export const updateCrmChannel = (newData) => async (dispatch) => {
   try {
      await CrmsService.updateChannel(newData)
      dispatch(updateCrmChannelSuccess(newData))
   } catch (err) {
      return Promise.reject(err)
   }
}

export const deleteCrmChannel = (id) => async (dispatch) => {
   try {
      await CrmsService.deleteChannel(id)
      dispatch(deleteCrmChannelSuccess(id))
   } catch (err) {
      console.log(err)
   }
}