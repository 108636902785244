import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import routeNames from '../../router/routeNames'
import { resetPassword } from '../../store/slices/auth/thunk-creators'

import ResetPasswordForm from '../../components/forms/ResetPasswordForm'

const ResetPasswordPage = () => {
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')
   
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleSubmit = async (data) => {
      try {
         await dispatch(resetPassword({
            token,
            ...data
         }))
         navigate(routeNames.RESET_PASSWORD_SUCCESS)
      } catch (err) {
         if (err.redirectToForgotPasswordPage) {
            setTimeout(() => navigate(routeNames.FORGOT_PASSWORD), 3000)
            return Promise.reject(err.status)
         }
         return Promise.reject(err)
      }
   }

   return (
      <ResetPasswordForm
         onSubmit={handleSubmit}
      />
   )
}

export default ResetPasswordPage