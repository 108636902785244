import { useSelector } from "react-redux"
import { channels_selectChannels } from "../store/slices/channels/selectors"
import { auth_selectUser } from "../store/slices/auth/selectors"
import { getCurrentChannelQty, getMaxForCreationChannelQty } from "../pages/PlanPage/utils"

const useCheckIsChannelCreationAllowed = (channelType) => {
   const channels = useSelector(channels_selectChannels)
   const { plan } = useSelector(auth_selectUser)

   const maxChannelQty = getMaxForCreationChannelQty(plan.planServices, channelType)
   const currentChannelQty = getCurrentChannelQty(channels, channelType)
   const isChannelLimitMaxedOut = currentChannelQty >= maxChannelQty
   
   return isChannelLimitMaxedOut
}

export default useCheckIsChannelCreationAllowed