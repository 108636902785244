import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   isAuth: false,
   user: null
}

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      checkAuthSuccess: (state, action) => {
         state.isAuth = true
         state.user = action.payload
      },
      loginSuccess: (state, action) => {
         state.isAuth = true
         state.user = action.payload
      },
      logoutSuccess: (state) => {
         state.isAuth = false
         state.user = null
      },
      updateUserSuccess: (state, action) => {
         state.user = {
            ...state.user,
            ...action.payload
         }
      },
      changeUserPlanSuccess: (state, action) => {
         state.user = {
            ...state.user,
            plan: {
               ...state.user.plan,
               planServices: state.user.plan.planServices.map((service) => {
                  return {
                     ...service,
                     quantity: action.payload.find(updatedService => updatedService.service === service.service).quantity
                  }
               })
            }
         }
      }
   }
})

export const { 
   checkAuthSuccess, 
   loginSuccess,
   logoutSuccess,
   updateUserSuccess,
   changeUserPlanSuccess
} = authSlice.actions
export default authSlice.reducer