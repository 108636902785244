import React, { useState } from 'react'

import './PlanPaymentPage.css'

import Page from '../../components/layouts/Page'
import Select from '../../components/formControls/Select'
import Radio from '../../components/formControls/Radio'
import { useLocation } from 'react-router-dom'
import Button from '../../components/buttons/Button'

const PlanPaymentPage = () => {
   const periodVariants = [
      {
         id: 1,
         name: '2 (60 дней, до 15.08.2024)'
      }
   ]
   const paymentVariants = [
      {
         id: 1,
         name: 'Банковская карта (РФ)',
         src: '/assets/icons/payment-card-icon.svg'
      },
      {
         id: 2,
         name: 'Банковская карта (СНГ и зарубежная)',
         src: '/assets/icons/payment-card-icon.svg'
      },
      {
         id: 3,
         name: 'СБП (QR-код)',
         src: '/assets/icons/sbp-icon.svg'
      }
   ]

   const location = useLocation()
   const selectedPlanItems = location.state
   console.log(selectedPlanItems);

   const [selectedPeriod, setSelectedPeriod] = useState(periodVariants[0])
   const [selectedPaymentVariant, setSelectedPaymentVariant] = useState(paymentVariants[0])

   const handlePeriodSelect = (period) => setSelectedPeriod(period)

   const handlePaymentVariantChange = (variant) => setSelectedPaymentVariant(variant)

   const handlePayBtnClick = () => console.log('Payment success')

   return (
      <Page
         title='Оплата'
         returnBtn
      >
         <div className='plan-payment-page-body'>
            <div className='plan-payment-page-constructor'>
               <div className='plan-payment-page-constructor__period'>
                  <h4 className='plan-payment-page-constructor__title'>
                     Срок тарифа
                  </h4>
                  <Select
                     className='plan-payment-page-constructor__select'
                     label='Количество периодов'
                     selectedValue={selectedPeriod}
                     values={periodVariants}
                     onSelect={handlePeriodSelect}
                  />
               </div>
               <div className='plan-payment-page-constructor__payment-variants'>
                  <h4 className='plan-payment-page-constructor__title'>
                     Способ оплаты
                  </h4>
                  <ul className='plan-payment-page-constructor__payment-variants-list'>
                     {paymentVariants.map(variant => (
                        <li
                           key={variant.id} 
                           className='plan-payment-page-constructor__payment-variants-list-item'
                        >
                           <label
                              className='plan-payment-page-constructor__payment-variant'
                           >
                              <Radio
                                 text={variant.name}
                                 checked={variant.id === selectedPaymentVariant.id}
                                 onChange={() => handlePaymentVariantChange(variant)}
                              />
                              <img className='plan-payment-page-constructor__payment-variant-icon' src={variant.src} alt='' />
                           </label>
                        </li>
                     ))}
                  </ul>
               </div>
            </div>
            <div className='plan-payment-page-total'>
               <h4 className='plan-payment-page-constructor__title'>
                  Стоимость
               </h4>
               <ul className='plan-payment-page-total__list'>
                  <li className='plan-payment-page-total__list-item'>
                     <div className='plan-payment-page-total__list-item-info'>
                        <h5 className='plan-payment-page-total__list-item-info-name'>
                           WhatsApp
                        </h5>
                        <div className='plan-payment-page-total__list-item-info-qty'>
                           2 канала
                        </div>
                        <div className='plan-payment-page-total__list-item-info-qty'>
                           2 периода
                        </div>
                     </div>
                     <div className='plan-payment-page-total__list-item-price'>
                        1 998 руб
                     </div>
                  </li>
                  <li className='plan-payment-page-total__list-item'>
                     <div className='plan-payment-page-total__list-item-info'>
                        <h5 className='plan-payment-page-total__list-item-info-name'>
                           Telegram
                        </h5>
                        <div className='plan-payment-page-total__list-item-info-qty'>
                           1 канал
                        </div>
                        <div className='plan-payment-page-total__list-item-info-qty'>
                           2 периода
                        </div>
                     </div>
                     <div className='plan-payment-page-total__list-item-price'>
                        1 998 руб
                     </div>
                  </li>
               </ul>
               <div className='plan-constructor__total-price'>
                  Итого:
                  <span>
                     2 997 руб
                  </span>
               </div>
               <Button
                  text='Оплатить'
                  onClick={handlePayBtnClick}
               />
            </div>
         </div>
      </Page>
   )
}

export default PlanPaymentPage