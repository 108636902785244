import React, { useState } from 'react'
import './DeleteChannelModal.css'
import BasicModal from '../BasicModal'
import Button from '../../buttons/Button'

const DeleteChannelModal = ({
   channelIdToDelete,
   isVisible, 
   onClose,
   onCancel,
   onConfirm
}) => {
   const [disableConfirmBtn, setDisableConfirmBtn] = useState(false)

   const handleConfirm = async () => {
      setDisableConfirmBtn(true)
      await onConfirm(channelIdToDelete)
      setDisableConfirmBtn(false)
   }

   return (
      <BasicModal
         title='Удалить канал?'
         isVisible={isVisible}
         onClose={onClose}
      >
         <div className='delete-channel-modal-body'>
            <Button
               className='btn_transparent'
               text='Отмена'
               onClick={onCancel}
            />
            <Button
               className='delete-channel-modal-confirm-btn'
               text='Да, удалить'
               disabled={disableConfirmBtn}
               onClick={handleConfirm}
            />
         </div>
      </BasicModal>
   )
}

export default DeleteChannelModal