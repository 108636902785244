import { 
   activateChannelSuccess,
   deactivateChannelSuccess,
   deleteChannelSuccess,
   fetchChannelsFinished, 
   fetchChannelsStarted, 
   fetchChannelsSuccess, 
   fetchWhatsappQrFinished, 
   fetchWhatsappQrStarted, 
   fetchWhatsappQrSuccess
} from "."
import ChannelsService from "../../../services/channelsService"

export const fetchChannels = () => async (dispatch) => {
   try {
      dispatch(fetchChannelsStarted())
      const data = await ChannelsService.fetchChannels()
      dispatch(fetchChannelsSuccess(data))
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchChannelsFinished())
   }
}

export const fetchWhatsappQR = () => async (dispatch) => {
   try {
      dispatch(fetchWhatsappQrStarted())
      const data = await ChannelsService.createChannel({
         channel: 'whatsapp'
      })
      dispatch(fetchWhatsappQrSuccess(data))
   } catch (err) {
      console.log(err)
   } finally {
      dispatch(fetchWhatsappQrFinished())
   }
}

export const createWhatsappChannel = (body) => async (dispatch) => {
   try {
      const data = await ChannelsService.setChannel(body)
      if (data.status === 'Начат процесс считывания QR кода') return true
   } catch (err) {
      console.log(err)
   }
}

export const createChannel = (body) => async (dispatch) => {
   try {
      const data = await ChannelsService.createChannel(body)

      if (data?.sourceId) return data.sourceId
   } catch (err) {
      if (err?.response?.data?.message?.data?.validation?.phone?.length) {
         return Promise.reject(err?.response?.data?.message?.data?.validation?.phone[0])
      } else if (err?.response?.data?.message?.data?.validation?.code?.length) {
         return Promise.reject('')
      } else if (err?.response?.data?.message?.data?.validation?.account_id?.length) {
         return Promise.reject(err?.response?.data?.message?.data?.validation?.account_id[0])
      } else {
         return Promise.reject()
      }
   }
}

export const createChannelWithCode = (body) => async (dispatch) => {
   try {
      const data = await ChannelsService.setChannel(body)

      if (data.message !== 'Канал установлен') return Promise.reject()
   } catch (err) {
      if (err?.response?.data?.message?.data?.validation?.account_id?.length) {
         return Promise.reject(err?.response?.data?.message?.data?.validation?.account_id[0])
      } else if (err?.response?.data?.message?.data?.validation?.code?.length) {
         return Promise.reject(err?.response?.data?.message?.data?.validation?.code[0])
      } else {
         return Promise.reject('Не удалось подключить канал!')
      }
   }
}

export const createChannelWithPassword = (body) => async (dispatch) => {
   try {
      await ChannelsService.setChannelWithPassword(body)
   } catch (err) {
      if (err?.response?.data?.message?.data?.validation?.twoFactorPassword?.length) {
         return Promise.reject(err?.response?.data?.message?.data?.validation?.twoFactorPassword[0])
      } else {
         return Promise.reject('Не удалось подключить канал!')
      }
   }
}

export const activateChannel = ({ id, phone, channel }) => async (dispatch) => {
   try {
      const data = await ChannelsService.activateChannel({ phone, channel })
      if (data.sourceId) {
         alert('Не удалось активировать канал. Вам был выслан код подтверждения')
         return Promise.reject(data)
      }

      dispatch(activateChannelSuccess(id))
   } catch (err) {
      console.log(err)
   }
}

export const activateChannelWithData = (body) => async (dispatch) => {
   try {
      const data = await ChannelsService.setChannel(body)
      if (data.message !== 'Канал установлен') return Promise.reject()

      dispatch(activateChannelSuccess(body.sourceId))
   } catch (err) {
      console.log(err)
   }
}

export const deactivateChannel = ({ id }) => async (dispatch) => {
   try {
      await ChannelsService.deactivateChannel(id)
      dispatch(deactivateChannelSuccess(id))
   } catch (err) {
      console.log(err)
   }
}

export const deleteChannel = (id) => async (dispatch) => {
   try {
      await ChannelsService.deleteChannel(id)
      dispatch(deleteChannelSuccess(id))
   } catch (err) {
      console.log(err)
   }
}