import React from 'react'
import './ProfileDataItem.css'

const profileDataItemLabelsMap = {
   name: {
      dataType: 'text',
      label: 'Имя'
   },
   email: {
      dataType: 'email',
      label: 'Почта'
   },
   tel: {
      dataType: 'tel',
      label: 'Телефон'
   }
}

const ProfileDataItem = ({ profileDataItemName = '', value, onEditBtnClick }) => {
   const handleEditBtnClick = () => {
      onEditBtnClick()
   }

   return (
      <li className='profile-data-item'>
         <div className='profile-data-item__name'>
            {profileDataItemName}
         </div>
         <div className='profile-data-item__value'>
            {value}
         </div>
         <div className='profile-data-item__actions'>
            <button
               type='button'
               className='profile-data-item__edit-btn'
               onClick={handleEditBtnClick}
            >
               <img src="/assets/icons/edit-icon.svg" alt="Edit" />
            </button>
         </div>
      </li>
   )
}

export default ProfileDataItem