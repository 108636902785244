export const messengersPageModals = {
   number: 'number',
   code: 'code',
   activateChannelCode: 'activateChannelCode',
   activateChannelPassword: 'activateChannelPassword',
   password: 'password',
   deleteChannel: 'deleteChannel'
}

export const defaultModalData = {
   name: '',
   data: null
}