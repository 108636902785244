import React from 'react'
import './Toggle.css'

const Toggle = ({ 
   text = '', 
   checked = false,
   disabled,
   onChange = () => {} 
}) => {
   const handleChange = () => {
      onChange()
   }

   return (
      <div className={`toggle ${checked ? 'active' : ''}`}>
         <label className='toggle__label'>
            <input
               className='toggle__input'
               type='checkbox'
               checked={checked}
               disabled={disabled}
               onChange={handleChange}
            />
            <div className='toggle__toggle'></div>
         </label>
         {text && (
            <p className='toggle__text'>
               {text}
            </p>
         )}
      </div>
   )
}

export default Toggle