import { useFormik } from 'formik'
import React, { useState } from 'react'

import './ActivateChannelPasswordForm.css'

import FormStatus from '../FormStatus'
import Input from '../../formControls/Input'
import Button from '../../buttons/Button'

const ActivateChannelPasswordForm = ({ data, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         password: ''
      },
      onSubmit: async ({ password }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            onSubmit({
               twoFactorPassword: password,
               sourceId: data
            })
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   return (
      <form 
         className='add-channel-password-form'
         onSubmit={formik.handleSubmit}
      >
         {formik.status && <FormStatus text={formik.status} />}
         <Input
            label='Пароль двухфакторной аутентификации'
            type='password'
            id='password'
            name='password'
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            placeholder='******'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
         />
         <Button
            className='add-channel-password-form__btn'
            type='submit'
            text='Активировать канал'
            disabled={disableSubmitBtn}
         />
      </form>
   )
}

export default ActivateChannelPasswordForm