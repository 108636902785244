import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './ProfilePage.css'

import { auth_selectUser } from '../../store/slices/auth/selectors'
import { updateUser } from '../../store/slices/auth/thunk-creators'

import Page from '../../components/layouts/Page'
import ProfileDataItem from './ProfileDataItem/ProfileDataItem'
import EditProfileDataItemModal from '../../components/modals/EditProfileDataItemModal'

const ProfilePage = () => {
   const {
      name,
      email,
      phone
   } = useSelector(auth_selectUser)

   const [showEditProfileDataItemModal, setShowEditProfileDataItemModal] = useState(false)
   const [profileDataItemToEdit, setProfileDataItemToEdit] = useState(null)

   const dispatch = useDispatch()

   const handleToggleEditProfileDataItemModal = () => setShowEditProfileDataItemModal(show => !show)

   const handleEditBtnClick = (data) => {
      setProfileDataItemToEdit(data)
      handleToggleEditProfileDataItemModal()
   }

   const handleEditProfileDataItemModalClose = () => setShowEditProfileDataItemModal(false)

   const handleEditProfileFormSubmit = async (data) => {
      if (!data) return handleToggleEditProfileDataItemModal()

      try {
         await dispatch(updateUser(data))
      } catch (err) {
         return Promise.reject(err)
      }
   }

   return (
      <Page
         title='Профиль'
      >
         <ul className='profile-data-list'>
            <ProfileDataItem
               profileDataItemName='Имя'
               value={name}
               onEditBtnClick={() => handleEditBtnClick({
                  dataType: 'text',
                  name
               })}
            />
            <ProfileDataItem
               profileDataItemName='Почта'
               value={email}
               onEditBtnClick={() => handleEditBtnClick({
                  dataType: 'email',
                  email
               })}
            />
            <ProfileDataItem
               profileDataItemName='Телефон'
               value={phone}
               onEditBtnClick={() => handleEditBtnClick({
                  dataType: 'tel',
                  phone
               })}
            />
         </ul>
         <EditProfileDataItemModal
            profileDataItemToEdit={profileDataItemToEdit}
            isVisible={showEditProfileDataItemModal}
            onClose={handleEditProfileDataItemModalClose}
            onCancel={handleEditProfileDataItemModalClose}
            onSubmit={handleEditProfileFormSubmit}
         />
      </Page>
   )
}

export default ProfilePage