import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

import '../PlanPage.css'

import { auth_selectUser } from '../../../store/slices/auth/selectors'
import { plan_selectCalculationError } from '../../../store/slices/plan/selectors'
import { getFormattedDate, getIsPlanActive } from '../utils'
import { calculatePlanPrice, createPayment, updateAutoPay } from '../../../store/slices/plan/thunk-creators'

import Page from '../../../components/layouts/Page'
import Toggle from '../../../components/formControls/Toggle'
import PlanConstructor from '../PlanConstructor'

const Plan = ({ plan }) => {
   const user = useSelector(auth_selectUser)
   const calculationError = useSelector(plan_selectCalculationError)
   const { planType, planEndAt, services } = plan
   const planName = planType === 0 ? 'Тестовый' : 'Кастомный'
   const planEndDate = getFormattedDate(planEndAt.date)
   const autoPay = user.autoPay
   const isPlanActive = getIsPlanActive(planEndAt.date, planType)
   
   const [disableAutoSubscriptionToggle, setDisableAutoSubscriptionToggle] = useState(!autoPay)
   const [isCalculating, setIsCalculating] = useState(!isPlanActive)
   const [calculatedPrice, setCalculatedPrice] = useState(0)
   const [shouldPay, setShouldPay] = useState(!isPlanActive)

   const dispatch = useDispatch()

   const handleAutoPayChange = async () => {
      setDisableAutoSubscriptionToggle(true)
      await dispatch(updateAutoPay({ autoPay: !autoPay }))
   }

   const handleCalculatePlanPrice = useCallback(debounce(async (planData, selectedPeriod) => {
      try {
         setIsCalculating(true)
         const { price, shouldPay } = await dispatch(calculatePlanPrice(planData))
         setCalculatedPrice(price)
         setShouldPay(shouldPay)
         if (shouldPay) {
            const { price } = await dispatch(calculatePlanPrice({...planData, periods: selectedPeriod}))
            setCalculatedPrice(price)
         }
      } catch (err) {

      } finally {
         setIsCalculating(false)
      }
   }, 500), [])

   const handleConstructorSubmit = async (planData) => {
      await dispatch(createPayment(planData))
   }

   return (
      <Page
         title='Покупка тарифа'
      >
         <div className='plan-page-body'>
            <div className='plan-page-current-plan'>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Текущий тариф
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     {`${planName} тариф`}
                  </div>
               </div>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Срок окончания тарифа
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     {planEndDate}
                  </div>
               </div>
               <div className='plan-page-current-plan-data-item'>
                  <div className='plan-page-current-plan-data-item__name'>
                     Автоматическая подписка
                  </div>
                  <div className='plan-page-current-plan-data-item__value'>
                     <Toggle
                        checked={autoPay}
                        disabled={disableAutoSubscriptionToggle}
                        onChange={handleAutoPayChange}
                     />
                  </div>
               </div>
            </div>
            <PlanConstructor
               isPlanActive={isPlanActive}
               services={services}
               autoPay={autoPay}
               isCalculating={isCalculating}
               calculatedPrice={calculatedPrice}
               shouldPay={shouldPay}
               onPlanChange={handleCalculatePlanPrice}
               onConstructorSubmit={handleConstructorSubmit}
               calculationError={calculationError}
            />
         </div>
      </Page>
   )
}

export default Plan