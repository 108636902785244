import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './AddChannelNumberForm.css'

import FormStatus from '../FormStatus'
import PhoneInput from '../../formControls/PhoneInput'
import Button from '../../buttons/Button'

const AddChannelNumberForm = ({ onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         phone: ''
      },
      validationSchema: yup.object().shape({
         phone: yup.string()
            .required('Введите номер телефона'),
      }),
      onSubmit: async (values, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            await onSubmit(values)
            setStatus(null)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const handleChange = (value) => {
      formik.setFieldValue('phone', value)
   }

   return (
      <form 
         className='add-channel-number-form'
         onSubmit={formik.handleSubmit}   
      >
         {formik.status && <FormStatus text={formik.status} />}
         <PhoneInput
            id='phone'
            name='phone'
            value={formik.values.phone}
            error={formik.touched.phone && formik.errors.phone}
            onChange={handleChange}
            onBlur={formik.handleBlur}
         />
         <Button
            className='add-channel-number-form__btn'
            type='submit'
            text='Получить код подтверждения'
            disabled={disableSubmitBtn}
         />
      </form>
   )
}

export default AddChannelNumberForm