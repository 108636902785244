import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'

import './AuthForm.css'

import routeNames from '../../../router/routeNames'
import Input from '../../formControls/Input'
import PhoneInput from '../../formControls/PhoneInput'
import Button from '../../buttons/Button'
import FormStatus from '../FormStatus'

const AuthForm = ({ isLoginPage, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         name: '',
         phone: '',
         email: '',
         password: '',
         repeatPassword: ''
      },
      validationSchema: yup.object().shape(
         isLoginPage
         ? {
            email: yup.string()
               .email('Некорректный формат электронной почты')
               .required('Введите почту'),
            password: yup.string()
               .required('Введите пароль'),
         }
         : {
            name: yup.string()
               .min(2, 'Длина имени должна быть минимум 2 символа')
               .required('Введите имя'),
            phone: yup.string()
               .matches(/^\+?\d{1,3}?[-\s]?\(?\d{1,3}\)?[-\s]?\d{3,6}[-\s]?\d{1,6}$/, 'Некорректный формат номера телефона')
               .required('Введите номер телефона'),
            email: yup.string()
               .email('Некорректный формат электронной почты')
               .required('Введите почту'),
            password: yup.string()
               .min(6, 'Длина пароля должна быть минимум 6 символов')
               .required('Введите пароль'),
            repeatPassword: yup.string()
               .oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
               .required('Поле "Повторить пароль" обязательно для заполнения')
         }
      ),
      onSubmit: async (values, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            const formData = isLoginPage
               ? {
                  login: values.email,
                  password: values.password
               }
               : {
                  ...values,
                  phone: '+' + values.phone
               }
            await onSubmit(formData)
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const handlePhoneChange = (value) => formik.setFieldValue('phone', value)

   useEffect(() => {
      formik.resetForm()
   }, [isLoginPage])

   return (
      <form 
         className='auth-form'
         onSubmit={formik.handleSubmit}
      >
         <div className='auth-form__header'>
            <Link className={`auth-form__link ${isLoginPage ? 'auth-form__link_active' : ''}`} to={routeNames.LOGIN}>
               Вход
            </Link>
            <Link className={`auth-form__link auth-form__link_registration ${!isLoginPage ? 'auth-form__link_active' : ''}`} to={routeNames.REGISTRATION}>
               Регистрация
            </Link>
         </div>
         <div className='auth-form__body'>
            {formik.status && <FormStatus text={formik.status} />}
            {!isLoginPage
               ? (
                  <>
                     <Input
                        label='Имя'
                        id='name'
                        name='name'
                        value={formik.values.name}
                        error={formik.touched.name && formik.errors.name}
                        placeholder='Введите имя'
                        maxLength={35}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                     <PhoneInput
                        id='phone'
                        name='phone'
                        value={formik.values.phone}
                        error={formik.touched.phone && formik.errors.phone}
                        onChange={handlePhoneChange}
                        onBlur={formik.handleBlur}
                     />
                     <Input
                        type='email'
                        label='Почта'
                        id='email'
                        name='email'
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                        placeholder='Введите почту'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                     <Input
                        type='password'
                        label='Пароль'
                        id='password'
                        name='password'
                        value={formik.values.password}
                        error={formik.touched.password && formik.errors.password}
                        placeholder='******'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                     <Input
                        type='password'
                        label='Повторите пароль'
                        id='repeatPassword'
                        name='repeatPassword'
                        value={formik.values.repeatPassword}
                        error={formik.touched.repeatPassword && formik.errors.repeatPassword}
                        placeholder='******'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                  </>
               ) : (
                  <>
                     <Input
                        type='email'
                        label='Почта'
                        id='email'
                        name='email'
                        value={formik.values.email}
                        error={formik.touched.email && formik.errors.email}
                        placeholder='Введите почту'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                     <Input
                        type='password'
                        label='Пароль'
                        id='password'
                        name='password'
                        value={formik.values.password}
                        error={formik.touched.password && formik.errors.password}
                        placeholder='******'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                     />
                  </>
               )
            }
         </div>
         <div className='auth-form__footer'>
            <Button
               type='submit'
               className='auth-form__submit-btn'
               text={isLoginPage ? 'Войти' : 'Зарегистрироваться'}
               disabled={disableSubmitBtn}
            />
            {isLoginPage && (
               <Link className='auth-form__forgot-password-link' to={routeNames.FORGOT_PASSWORD}>
                  Забыли пароль?
               </Link>
            )}
         </div>
      </form>
   )
}

export default AuthForm