import React from 'react'
import './Loader.css'

const Loader = () => {
   return (
      <div className='loader'>
         <div className='loader__loader'></div>
      </div>
   )
}

export default Loader