import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import './NavBar.css'

import navLinks from './navLinks'
import { logout } from '../../store/slices/auth/thunk-creators'
import { auth_selectUser } from '../../store/slices/auth/selectors'

const NavBar = () => {
   const { id, externalUserId } = useSelector(auth_selectUser)
   const dispatch = useDispatch()

   const handleLogout = async () => {
      dispatch(logout())
   }

   return (
      <div className='navbar'>
         <div className='navbar__header'>
            <div className='navbar__user-id'>
               {`id ${id}-${externalUserId}`}
            </div>
         </div>
         <nav className='navbar__nav-list'>
            {navLinks.map(({ path, icon, label }) => (
               <NavLink
                  key={path}
                  to={path}
                  className={`navbar__nav-link ${({ isActive }) => isActive
                     ? 'active'
                     : ''
                  }`}
               >
                  <img src={icon} alt={label} />
                  {label}
               </NavLink>
            ))}
         </nav>
         <div className='navbar__footer'>
            <button 
               type='button'
               className='navbar__nav-link navbar__logout-btn'
               onClick={handleLogout}
            >
               <img src='/assets/icons/logout-link-icon.svg' alt='Выход' />
               Выход
            </button>
         </div>
      </div>
   )
}

export default NavBar