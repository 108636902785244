import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './EditCrmManagerForm.css'

import Input from '../../formControls/Input'
import Button from '../../buttons/Button'

const EditCrmManagerForm = ({ manager, onCancel, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         email: manager || ''
      },
      validationSchema: yup.object().shape({
         email: yup.string()
            .email('Некорректный формат электронной почты')
            .required('Введите почту')
      }),
      onSubmit: async ({ email }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            if (email === manager) return onCancel()
            await onSubmit({ responsibleUserEmail: email })
            onCancel()
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const handleCancelBtnClick = () => onCancel()

   return (
      <form 
         className='edit-crm-manager-form'
         onSubmit={formik.handleSubmit}
      >
         <div className='edit-crm-manager-form__body'>
            <Input
               type='email'
               id='email'
               name='email'
               value={formik.values.email}
               error={formik.touched.email && formik.errors.email}
               placeholder='manager@gmail.ru'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
         </div>
         <div className='edit-profile-data-item-form__footer'>
            <Button
               className='btn_transparent'
               text='Отмена'
               onClick={handleCancelBtnClick}
            />
            <Button
               type='submit'
               text='Сохранить'
               disabled={disableSubmitBtn}
            />
         </div>
      </form>
   )
}

export default EditCrmManagerForm