import React from 'react'
import { Outlet } from 'react-router-dom'
import './AuthLayout.css'

const AuthLayout = () => {
   return (
      <div className='auth-layout'>
         <div className='logo'>
            <img src='/assets/icons/logo.svg' alt='Logo' />
         </div>
         <Outlet />
      </div>
   )
}

export default AuthLayout