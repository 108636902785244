import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Page.css'

const Page = ({ title = '', returnBtn = false, children }) => {
   const navigate = useNavigate()

   const handleReturnBtnClick = () => navigate(-1)

   return (
      <div className='page'>
         {title && (
            <div className='page__header'>
               {returnBtn && (
                  <button
                     className='page__return-btn'
                     onClick={handleReturnBtnClick}
                  >
                     <img src="/assets/icons/return-icon.svg" alt="Return" />
                  </button>
               )}
               <h2 className='page__title'>
                  {title}
               </h2>
            </div>
         )}
         <div className='page__content'>
            {children}
         </div>
      </div>
   )
}

export default Page