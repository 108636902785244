import $axios from "../http"

class AuthService {
   static fetchUser = async () => {
      const { data } = await $axios.get('user/get')
      return data
   }

   static updateUser = async (body) => {
      const { data } = await $axios.patch('user/profile', body)
      return data
   }

   static register = async (body) => {
      const { data } = await $axios.post('user/register', body)
      return data
   }

   static login = async (body) => {
      const { data } = await $axios.post('login', body)
      return data
   }

   static logout = async () => {
      const { data } = await $axios.get('logout')
      return data
   }

   static requestResetPassword = async (body) => {
      const { data } = await $axios.post('user/request-reset-password', body)
      return data
   }

   static resetPassword = async ({ token, ...rest }) => {
      const { data } = await $axios.post(`user/reset-password?token=${token}`, rest)
      return data
   }
}

export default AuthService