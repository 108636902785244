import React from 'react'
import BasicModal from '../BasicModal'
import AddChannelPasswordForm from '../../forms/AddChannelPasswordForm'

const AddChannelPasswordModal = ({ isVisible, data, onClose, onSubmit }) => {
   return (
      <BasicModal
         title='Добавить канал'
         isVisible={isVisible}
         onClose={onClose}
      >
         <AddChannelPasswordForm
            data={data}
            onSubmit={onSubmit}
         />
      </BasicModal>
   )
}

export default AddChannelPasswordModal