import React from 'react'
import Input from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'
import './PhoneInput.css'

const PhoneInput = ({
   label = 'Телефон',
   id,
   name,
   value,
   error,
   placeholder = '',
   onChange = () => {},
   onBlur = () => {}
}) => {
   return (
      <div className={`phone-input ${error ? 'error' : ''}`}>
         <label 
            htmlFor={id}
            className='phone-input__label'
         >
            {label}
         </label>
         <Input
            localization={ru}
            regions={['europe', 'ex-ussr', 'ge']}
            inputClass='phone-input__input'
            country={'ru'}
            inputProps={{
               id,
               name
            }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
         />
         {error && (
            <p className='phone-input__error-message'>
               {error}
            </p>
         )}
      </div>
   )
}

export default PhoneInput