import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'

import './AddWhatsappPage.css'

import routeNames from '../../router/routeNames'
import ChannelsService from '../../services/channelsService'
import { clearWhatsappChannelData } from '../../store/slices/channels'
import { channels_selectQrCode, channels_selectQrLoading, channels_selectSourceId } from '../../store/slices/channels/selectors'
import { createWhatsappChannel, fetchWhatsappQR } from '../../store/slices/channels/thunk-creators'
import { instructions } from './instructions'

import Page from '../../components/layouts/Page'

const AddWhatsappPage = () => {
   const qrLoading = useSelector(channels_selectQrLoading)
   const qrCode = useSelector(channels_selectQrCode)
   const sourceId = useSelector(channels_selectSourceId)

   const [isScanningStarted, setIsScanningStarted] = useState(false)
   const [isChannelScanned, setIsChannelScanned] = useState(false)
   const shouldStopCheckingIsQrScanned = useRef(false)

   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleFetchQrCode = () => {
      dispatch(fetchWhatsappQR())
   }

   const handleCreateWhatsappChannel = async (sourceId) => {
      const data = await dispatch(createWhatsappChannel({ sourceId }))
      if (data) setIsScanningStarted(true)
   }

   useEffect(() => {
      handleFetchQrCode()

      return () => {
         dispatch(clearWhatsappChannelData())
         shouldStopCheckingIsQrScanned.current = true
      }
   }, [])

   useEffect(() => {
      if (sourceId) handleCreateWhatsappChannel(sourceId)
   }, [sourceId])

   useEffect(() => {
      if (isScanningStarted) {
         const checkIsQrCodeScanned = async (tryNumber = 1) => {
            try {
               const data = await ChannelsService.checkIsQrCodeScanned(sourceId)
                  if (data.message === 'Канал установлен') {
                     return setIsChannelScanned(true)
                  }
            } catch (err) {
               if (err.response.data.message === 'QR код не считан' && tryNumber < 15 && !shouldStopCheckingIsQrScanned.current) {
                  await new Promise((resolve) => setTimeout(resolve, 3000))
                  await checkIsQrCodeScanned(tryNumber + 1)
               }
            }
         }

         checkIsQrCodeScanned()
      }

   }, [isScanningStarted])

   useEffect(() => {
      if (isChannelScanned) navigate(routeNames.MESSENGERS)
   }, [isChannelScanned])

   return (
      <Page
         title='Whatsapp'
         returnBtn
      >
         <div className='add-whatsapp-page-body'>
            <p className='add-whatsapp-page-description'>
               Небольшая инструкция по подключению канала WhatsApp:
            </p>
            <ul className='add-whatsapp-page-instructions-list'>
               {instructions.map((text, i) => (
                  <li key={i} className='add-whatsapp-page-instructions__list-item'><span>{`${i + 1}.`}</span>{text}</li>
               ))}
            </ul>
            <div className='add-whatsapp-page-qr-code'>
               {qrLoading
                  ? <div className='qr-loader'></div>
                  : (
                     <QRCode 
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qrCode}
                     />
               )}
            </div>
         </div>
      </Page>
   )
}

export default AddWhatsappPage