import React from 'react'
import './ResetPasswordEmailSentPage.css'

const ResetPasswordEmailSentPage = () => {
   return (
      <div className='reset-password-email-sent-message'>
         <img className='reset-password-email-sent-message__img' src='/assets/images/reset-email-sent.png' alt='Success' />
         <h4 className='reset-password-email-sent-message__title'>
            Восстановление пароля
         </h4>
         <p className='reset-password-email-sent-message__text'>
            Мы отправили письмо на вашу почту со ссылкой для сброса пароля.
         </p>
      </div>
   )
}

export default ResetPasswordEmailSentPage