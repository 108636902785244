import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './EditProfileDataItemForm.css'

import Input from '../../formControls/Input'
import Button from '../../buttons/Button'
import PhoneInput from '../../formControls/PhoneInput'
import FormStatus from '../FormStatus'

const getInputData = (dataItem, formik) => {
   switch (dataItem?.dataType) {
      case 'email':
         return {
            type: 'text',
            id: 'email',
            name: 'email',
            value: formik.values.email,
            error: formik.touched.email && formik.errors.email,
            placeholder: 'Введите email'
         }
      case 'tel': 
         return {
            id: 'phone',
            name: 'phone',
            value: formik.values.phone,
            error: formik.touched.phone && formik.errors.phone
         }
      default:
         return {
            type: 'text',
            id: 'name',
            name: 'name',
            value: formik.values.name,
            error: formik.touched.name && formik.errors.name,
            placeholder: 'Введите имя'
         }
   }
}

const getValidationSchema = (profileDataItemType = '') => {
   switch (profileDataItemType) {
      case 'email':
         return {
            email: yup.string()
               .matches(
                  /^[^\s@]+@[^\s@.]+\.[^\s@.]+$/,
                  'Пожалуйста, введите корректный email-адрес'
               )
               .required('Введите почту')
         }
      case 'text':
         return {
            name: yup.string()
               .required('Введите имя')
         }
      default:
         return {
            phone: yup.string()
               .required('Введите номер телефона')
         }
   }
}

const EditProfileDataItemForm = ({ profileDataItemToEdit, onCancel, onSubmit }) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         name: profileDataItemToEdit?.name || '',
         email: profileDataItemToEdit?.email || '',
         phone: profileDataItemToEdit?.phone || ''
      },
      validationSchema: yup.object().shape(
         getValidationSchema(profileDataItemToEdit?.dataType)
      ),
      onSubmit: async ({ name, email, phone }, { setStatus }) => {
         try {
            setDisableSubmitBtn(true)
            let data = null
            switch (profileDataItemToEdit?.dataType) {
               case 'email': 
                  email !== profileDataItemToEdit.email && (data = { email })
                  break;
               case 'tel': 
                  phone !== profileDataItemToEdit.phone && (data = { phone })
                  break;
               default: 
                  name !== profileDataItemToEdit.name && (data = { name })
            }
            await onSubmit(data)
            onCancel()
         } catch (err) {
            setStatus(err)
         } finally {
            setDisableSubmitBtn(false)
         }
      }
   })

   const { type, id, name, value, error, placeholder } = getInputData(profileDataItemToEdit, formik)

   const handleCancelBtnClick = () => onCancel()

   const handleChange = (value) => {
      formik.setFieldValue('phone', '+' + value)
   }

   useEffect(() => {
      formik.setStatus(null)
      formik.setValues({
         ...formik.initialValues,
         name: profileDataItemToEdit?.name || '',
         email: profileDataItemToEdit?.email || '',
         phone: profileDataItemToEdit?.phone || '' 
      })
   }, [profileDataItemToEdit])

   return (
      <form 
         className='edit-profile-data-item-form'
         onSubmit={formik.handleSubmit}
      >
         <div className='edit-profile-data-item-form__body'>
            {formik.status && (
               <FormStatus 
                  className='edit-profile-data-item-form__status' 
                  text={formik.status} 
               />
            )}
            {profileDataItemToEdit?.dataType === 'tel'
               ? (
                  <PhoneInput 
                     id={id}
                     name={name}
                     value={value}
                     error={error}
                     onChange={handleChange}
                     onBlur={formik.handleBlur}
                  />
               ) : (
                  <Input
                     type={type}
                     id={id}
                     name={name}
                     value={value}
                     error={error}
                     placeholder={placeholder}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     maxLength={40}
                  />
               )}
         </div>
         <div className='edit-profile-data-item-form__footer'>
            <Button
               className='btn_transparent'
               text='Отмена'
               onClick={handleCancelBtnClick}
            />
            <Button
               type='submit'
               text='Сохранить'
               disabled={disableSubmitBtn}
            />
         </div>
      </form>
   )
}

export default EditProfileDataItemForm