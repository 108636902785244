import React, { useState } from 'react'
import './Select.css'
import useClickOutside from '../../../hooks/useClickOutside'

const Select = ({
   className= '',
   label = '',
   selectedValue,
   values,
   onSelect
}) => {
   const [active, setActive] = useState(false)

   const handleToggleSelect = () => setActive(active => !active)

   const handleSelect = (value) => {
      if (values.length > 1) onSelect(value)
      handleToggleSelect()
   }

   const ref = useClickOutside(() => setActive(false))

   return (
      <div className={`select ${active ? 'active' : ''} ${className}`} ref={ref}>
         {label && (
            <label
               className='select__label'
            >
               {label}
            </label>
         )}
         <div className='select__select'>
            <button 
               className='select__selected-value'
               onClick={handleToggleSelect}
            >
               {selectedValue.name}
               <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 1L9 9L1 0.999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </button>
            <ul className='select__list'>
               {values.map(value => {
                  const isSelected = value.name === selectedValue.name

                  return (
                     <li
                        key={value.id} 
                        className={`select__list-item ${isSelected ? 'selected' : ''}`}
                        onClick={() => handleSelect(value)}
                     >
                        {value.name}
                     </li>
                  )
               })}
            </ul>
         </div>
      </div>
   )
}

export default Select