import React from 'react'
import { Outlet } from 'react-router-dom'
import './MainLayout.css'
import NavBar from '../../NavBar'

const MainLayout = () => {
   return (
      <div className='main-layout'>
         <NavBar />
         <div className='main-layout__page-container'>
            <Outlet />
         </div>
      </div>
   )
}

export default MainLayout