import React from 'react'
import BasicModal from '../BasicModal'
import AddChannelNumberForm from '../../forms/AddChannelNumberForm'
import 'react-phone-input-2/lib/style.css'

const AddChannelNumberModal = ({ isVisible, onClose, onSubmit }) => {
   return (
      <BasicModal
         title='Добавить канал'
         isVisible={isVisible}
         onClose={onClose}
      >
         <AddChannelNumberForm
            onSubmit={onSubmit}
         />
      </BasicModal>
   )
}

export default AddChannelNumberModal