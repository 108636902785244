import React from 'react'
import { useNavigate } from 'react-router-dom'

import './ResetPasswordSuccessPage.css'

import routeNames from '../../router/routeNames'

import Button from '../../components/buttons/Button'

const ResetPasswordSuccessPage = () => {
   const navigate = useNavigate()

   const handleClick = () => navigate(routeNames.LOGIN)

   return (
      <div className='reset-password-success-message'>
         <img className='reset-password-success-message__img' src='/assets/images/reset-password-success.png' alt='Success' />
         <h4 className='reset-password-success-message__title'>
            Поздравляем!
         </h4>
         <p className='reset-password-success-message__text'>
            Ваш пароль был успешно восстановлен. Можете перейти на страницу авторизации. 
         </p>
         <Button
            className='reset-password-success-message__btn'
            text='Войти'
            onClick={handleClick}
         />
      </div>
   )
}

export default ResetPasswordSuccessPage