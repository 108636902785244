import React from 'react'
import './CrmChannelsList.css'
import CrmChannelsListItem from './CrmChannelsListItem'

const CrmChannelsList = ({ channels, handleStatusChange, handleEditBtnClick, handleDeleteBtnClick }) => {
   return (
      <div className='crm-channels-list'>
         <div className='crm-channels-list__header'>
            <div className='crm-channels-list__column crm-channels-list__column_crm'>
               CRM
            </div>
            <div className='crm-channels-list__column crm-channels-list__column_domen'>
               Домен портала
            </div>
            <div className='crm-channels-list__column crm-channels-list__column_status'>
               Статус
            </div>
            <div className='crm-channels-list__column crm-channels-list__column_actions'>
            </div>
         </div>
         <ul className='crm-channels-list__list'>
            {channels.map(channel => (
               <CrmChannelsListItem
                  key={channel.id}
                  channel={channel}
                  onStatusChange={handleStatusChange}
                  onEditBtnClick={handleEditBtnClick}
                  onDeleteBtnClick={handleDeleteBtnClick}
               />
            ))}
         </ul>
      </div>
   )
}

export default CrmChannelsList