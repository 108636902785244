import React from 'react'
import './BasicModal.css'

const BasicModal = ({ 
   title = '',
   isVisible, 
   showCloseBtn = true,
   children,
   onClose = () => {}
}) => {
   const handleClose = () => {
      onClose()
   }

   return (
      <div className={`modal ${isVisible ? 'active' : ''}`}>
         <div className='modal__body'>
            {showCloseBtn && (
               <button 
                  className='modal__close-btn'
                  onClick={handleClose}
               >
                  <img src='/assets/icons/close-icon.svg' alt='Close' />
               </button>
            )}
            {title && (
               <h4 className='modal__title'>
                  {title}
               </h4>
            )}
            {children}
         </div>
      </div>
   )
}

export default BasicModal