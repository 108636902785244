import React from 'react'
import { useNavigate } from 'react-router-dom'
import './RegistrationSuccessPage.css'

import routeNames from '../../router/routeNames'

import Button from '../../components/buttons/Button'

const RegistrationSuccessPage = () => {
   const navigate = useNavigate()

   const handleClick = () => navigate(routeNames.LOGIN)

   return (
      <div className='registration-success-message'>
         <img className='registration-success-message__img' src='/assets/images/registration-success.png' alt='Success' />
         <h4 className='registration-success-message__title'>
            Поздравляем!
         </h4>
         <p className='registration-success-message__text'>
            Ваша регистрация прошла успешно. Теперь вы можете войти в систему. 
         </p>
         <Button
            className='registration-success-message__btn'
            text='Войти'
            onClick={handleClick}
         />
      </div>
   )
}

export default RegistrationSuccessPage