import React, { useState } from 'react'
import './SelectDealStatusModal.css'

import BasicModal from '../BasicModal'
import Select from '../../formControls/Select'
import Button from '../../buttons/Button'
import FormStatus from '../../forms/FormStatus'

const SelectDealStatusModal = ({ isVisible, data, onClose, onSubmit }) => {
   const { targetId, responsibleUsers, statuses } = data

   const [selectedStatus, setSelectedStatus] = useState(statuses[0])
   const [status, setStatus] = useState('')
   const [disableBtn, setDisableBtn] = useState(false)

   const handleStatusChange = (status) => setSelectedStatus(status)

   const handleSubmit = async () => {
      try {
         setDisableBtn(true)
         await onSubmit({
            targetId,
            responsibleUserId: responsibleUsers[0].id + '',
            responsibleUserEmail: responsibleUsers[0].name,
            statusId: selectedStatus.id,
            statuses: statuses
         })
      } catch (err) {
         setStatus(err)
      } finally {
         setDisableBtn(false)
      }
   }

   return (
      <BasicModal
         title='Добавить канал'
         isVisible={isVisible}
         onClose={onClose}
      >
         <div className='select-deal-status-modal-body'>
            {status && (
               <FormStatus 
                  className='select-deal-status-modal-status' 
                  text={status} 
               />
            )}
            <Select
               className='select-deal-status-modal-select'
               label = 'Выберите статус создаваемой сделки'
               values={statuses}
               selectedValue={selectedStatus}
               onSelect={handleStatusChange}
            />
         </div>
         <div className='select-deal-status-modal-footer'>
            <Button
               className='select-deal-status-modal-btn'
               text='Добавить канал'
               onClick={handleSubmit}
               disabled={disableBtn}
            />
         </div>
      </BasicModal>
   )
}

export default SelectDealStatusModal