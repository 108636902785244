import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   crmsLoading: false,
   crms: [],
   crmData: {}
}

export const crmsSlice = createSlice({
   name: 'crms',
   initialState,
   reducers: {
      fetchCrmsStarted: (state) => {
         state.crmsLoading = true
      },
      fetchCrmsSuccess: (state, action) => {
         state.crms = [ ...action.payload ]
      },
      fetchCrmsFinished: (state) => {
         state.crmsLoading = false
      },
      fetchCrmDataSuccess: (state, action) => {
         state.crmData = action.payload
      },
      updateCrmChannelSuccess: (state, action) => {
         state.crms = state.crms.map(crm => (
            crm.id === action.payload.id
               ? { ...crm, ...action.payload.body }
               : crm
         ))
         if (action.payload.body.responsibleUserEmail) {
            state.crmData = Object.keys(state.crmData).length > 0
               ? { ...state.crmData, username: action.payload.body.responsibleUserEmail }
               : state.crmData
         } else {
            state.crmData = Object.keys(state.crmData).length > 0
               ? { ...state.crmData, ...action.payload.body }
               : state.crmData
         }
      },
      deleteCrmChannelSuccess: (state, action) => {
         state.crms = state.crms.filter(crm => crm.id !== action.payload)
      }
   }
})

export const { 
   fetchCrmsStarted, 
   fetchCrmsSuccess,
   fetchCrmsFinished,
   fetchCrmDataSuccess,
   updateCrmChannelSuccess,
   deleteCrmChannelSuccess,
} = crmsSlice.actions
export default crmsSlice.reducer