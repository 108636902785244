import React, { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { channels_selectChannels, channels_selectChannelsLoading } from '../../store/slices/channels/selectors'
import { auth_selectUser } from '../../store/slices/auth/selectors'
import { fetchChannels } from '../../store/slices/channels/thunk-creators'
import { getPageFromChannelParam } from './utils'
import { getIsPlanEnded } from '../PlanPage/utils'
import routeNames from '../../router/routeNames'
import useCheckIsChannelCreationAllowed from '../../hooks/useCheckIsChannelCreationAllowed'

import Loader from '../../components/layouts/Loader'

const AddChannelPageWrapper = () => {
   const { channel } = useParams()

   const { planEndAt } = useSelector(auth_selectUser)
   const channels = useSelector(channels_selectChannels)
   const channelsLoading = useSelector(channels_selectChannelsLoading)
   const isChannelLimitMaxedOut = useCheckIsChannelCreationAllowed(channel)
   const isPlanEnded = getIsPlanEnded(planEndAt)
   const isAllowedToCreateChannel = !isChannelLimitMaxedOut && !isPlanEnded
   
   const PageComponent = getPageFromChannelParam(channel)

   const dispatch = useDispatch()

   useEffect(() => {
      if (!channels.length) {
         dispatch(fetchChannels())
      }
   }, [])

   if (channelsLoading) return <Loader />

   if (!isAllowedToCreateChannel || !PageComponent) return <Navigate to={routeNames.MESSENGERS} />

   return <PageComponent />
}

export default AddChannelPageWrapper