import React, { useState } from 'react'

import './CrmChannelsList.css'

import { channelsMap } from '../utils/constants'

import Toggle from '../../../components/formControls/Toggle'

const CrmChannelsListItem = ({ channel, onStatusChange, onEditBtnClick, onDeleteBtnClick }) => {
   const { id, channel: { type }, domain, status } = channel
   const isChannelActive = status.id === 1
   const { channelIcon } = channelsMap[type]

   const [disableToggle, setDisableToggle] = useState(false)

   const handleStatusChange = async () => {
      setDisableToggle(true)
      await onStatusChange({
         id,
         body: {
            status: {
               id: isChannelActive ? 0 : 1
            }
         }
      })
      setDisableToggle(false)
   }

   const handleEditBtnClick = () => {
      onEditBtnClick(id)
   }

   const handleDeleteBtnClick = () => {
      onDeleteBtnClick(id)
   }

   return (
      <li className='crm-channels-list-item'>
         <div className='crm-channels-list__column crm-channels-list__column_crm'>
            <div className='crm-channels-list-item__crm'>
               <img src={channelIcon} alt="" />
            </div>
         </div>
         <div className='crm-channels-list__column crm-channels-list__column_domen'>
            <span className='crm-channels-list-item__domen'>{domain || 'domain'}</span>
         </div>
         <div className='crm-channels-list__column crm-channels-list__column_status'>
            <Toggle
               text={isChannelActive ? 'Активный' : 'Неактивый'}
               checked={isChannelActive}
               disabled={disableToggle}
               onChange={handleStatusChange}
            />
         </div>
         <div className='crm-channels-list__column crm-channels-list__column_actions'>
            <button
               className='crm-channels-list-item__edit-btn'
               onClick={handleEditBtnClick}
            >
               <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3631 3.65153L12.843 2.17154C13.6241 1.39049 14.8904 1.39049 15.6715 2.17154L17.0857 3.58576C17.8667 4.3668 17.8667 5.63313 17.0857 6.41418L15.6057 7.89417M11.3631 3.65153L1.74742 13.2672C1.41535 13.5993 1.21072 14.0376 1.1694 14.5054L0.927315 17.2459C0.872545 17.8659 1.39141 18.3848 2.01143 18.33L4.75184 18.0879C5.21965 18.0466 5.658 17.8419 5.99007 17.5099L15.6057 7.89417M11.3631 3.65153L15.6057 7.89417" stroke="#5570FD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </button>
            <button 
               className='crm-channels-list-item__delete-btn'
               onClick={handleDeleteBtnClick}
            >
               <img src='/assets/icons/delete-icon.svg' alt='Delete' />
            </button>
         </div>
      </li>
   )
}

export default CrmChannelsListItem