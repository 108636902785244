import $axios from "../http"

class PlanService {
   static fetchPlan = async () => {
      const { data } = await $axios.get('plan-get')
      return data
   }

   static calculatePlanPrice = async (body) => {
      const { data } = await $axios.post('payment-calculate', body)
      return data
   }

   static updateAutoPay = async (body) => {
      const { data } = await $axios.patch('user/profile', body)
      return data
   }

   static createPayment = async (body) => {
      const { data } = await $axios.post('payment-create', body)
      return data
   }

   static redirectToPaymentPage = async (body) => {
      window.open(`${process.env.REACT_APP_SERVER_URL}payment-redirect?confirmationUrl=${body}`)
   }
}

export default PlanService