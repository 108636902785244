import React from 'react'
import BasicModal from '../BasicModal'
import EditCrmManagerForm from '../../forms/EditCrmManagerForm'

const EditCrmManagerModal = ({ 
   isVisible, 
   manager,
   onClose,
   onCancel,
   onSubmit
}) => {
   return (
      <BasicModal
         title='Ответственный за заявки'
         isVisible={isVisible}
         onClose={onClose}
      >
         {isVisible && (
            <EditCrmManagerForm
               manager={manager}
               onCancel={onCancel}
               onSubmit={onSubmit}
            />
         )}
      </BasicModal>
   )
}

export default EditCrmManagerModal